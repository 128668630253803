<template>
  <v-container align="center" fluid class="d-flex justify-center mb-15">
    <v-row class="d-flex justify-center">
      <v-col cols="12" class="d-flex justify-center pb-0">
        <v-card width="850" elevation="3" id="dataapp">
          <v-card-title class="ml-10 mt-5"> </v-card-title>
          <v-card-text>
            <v-row class="ml-10" style="border-bottom: 1px groove">
              <v-col cols="9" class="mt-7">
                <v-row class="d-flex flex-column">
                  <v-col class="pt-0">
                    <p style="font-size: 2.1rem; color: #757575">HUB REQUEST</p>
                  </v-col>
                  <v-col class="py-0"
                    ><p class="font-weight-medium py-0 mb-0">
                      REQUEST FROM :
                    </p></v-col
                  >
                  <v-col class="pb-0"
                    ><p class="mb-0 text-body-1">
                      {{ companyinfo.name }}
                    </p></v-col
                  >
                  <v-col cols="4" class="py-0">
                    <p class="text-body-1 mb-0">{{ companyinfo.location }}</p>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>

            <v-row class="ml-10 pa-0 mt-5">
              <v-col class="py-0 mb-0" cols="8"
                ><p class="mb-0 font-weight-medium">REQUEST TO :</p></v-col
              >
              <v-col cols="4" class="py-0 d-flex flex-row">
                <p class="text-body-1 mb-0">HUB REQUEST #</p>
                <v-spacer></v-spacer>
                <p class="text-body-1 mb-0 mr-13">{{ billTo.id }}</p>
              </v-col>

              <v-col cols="8" class="py-0">
                <p class="text-body-1 mb-0 mt-2">
                  {{ billTo.name }}
                </p>
              </v-col>
              <v-col cols="4" class="py-0 d-flex flex-row">
                <v-col class="py-0 d-flex flex-row pl-0 mr-5">
                  <p class="text-body-1 mb-0">Date:</p>
                  <v-spacer></v-spacer>
                  <p class="text-body-1 mb-0 mr-5">
                    {{ new Date(request.date).toDateString() }}
                  </p>
                </v-col>
              </v-col>
              <v-col cols="8" class="py-0">
                <p class="text-body-1 mb-0">{{ billTo.location }}</p>
              </v-col>
              <v-col cols="4" class="py-0" dense>
                <div
                  class="d-flex flex-row align-center mr-12"
                  style="background-color: #cfd3d4"
                >
                  <p class="mt-2 mb-1 font-weight-medium ml-2 mb-2">
                    {{ request.is_approved }}
                  </p>
                </div>
              </v-col>
            </v-row>
            <div>
              <v-card min-height="500" elevation="0" class="mt-2">
                <v-data-table
                  :headers="headers"
                  :items="orderAllArray"
                  item-key="id"
                  class="elevation-0 mx-12"
                  hide-default-footer
                  dense
                  :single-expand="singleExpand"
                  :expanded.sync="expanded"
                  show-expand
                >
                  <!-- <template v-slot:expanded-item="{ headers, item }">
                    <td
                      :colspan="headers.length"
                      v-if="item.status == 'Pending'"
                      class="py-5"
                    >
                      <v-row>
                        <v-col cols="4">
                          <p class="mb-0">Approve Quantity</p>
                        </v-col>
                        <v-col cols="4">
                          <p class="mb-0">Damage Quantity</p>
                        </v-col>
                        <v-col cols="4">
                          <p class="mb-0">Not Found Quantity</p>
                        </v-col>
                        <v-col cols="4" class="mt-0 pb-0">
                          <v-text-field
                            v-model="item.approved_qty"
                            outlined
                            dense
                            label="Approve Quantity"
                            type="number"
                            :rules="[
                              () =>
                                item.requested_qty >=
                                  Number(
                                    Number(item.approved_qty) +
                                      Number(item.damaged_qty) +
                                      Number(item.not_found_qty)
                                  ) || 'Please Decrease Quantity',
                            ]"
                          ></v-text-field>
                        </v-col>

                        <v-col cols="4" class="mt-0 pb-0">
                          <v-text-field
                            v-model="item.damaged_qty"
                            outlined
                            dense
                            label="Damage Quantity"
                            type="number"
                            :rules="[
                              () =>
                                item.requested_qty >=
                                  Number(
                                    Number(item.approved_qty) +
                                      Number(item.damaged_qty) +
                                      Number(item.not_found_qty)
                                  ) || 'Please Decrease Quantity',
                            ]"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="4" class="mt-0 pb-0">
                          <v-text-field
                            v-model="item.not_found_qty"
                            outlined
                            dense
                            label=" Not Found Quantity"
                            type="number"
                            :rules="[
                              () =>
                                item.requested_qty >=
                                  Number(
                                    Number(item.approved_qty) +
                                      Number(item.damaged_qty) +
                                      Number(item.not_found_qty)
                                  ) || 'Please Decrease Quantity',
                            ]"
                          ></v-text-field>
                        </v-col>
                        <v-col class="pt-0" cols="9"></v-col>
                        <v-col class="pt-0" cols="2">
                          <v-btn @click="Approveqty(item)" color="primary">
                            approve
                          </v-btn>
                        </v-col>
                      </v-row>
                    </td>
                  </template> -->
                </v-data-table>
              </v-card>
            </div>
            <v-row>
              <v-spacer></v-spacer>
              <v-col cols="3">
                <v-btn
                  color="primary"
                  dark
                  @click="AllConfirm"
                  :disabled="confirmbtn != true"
                >
                  All Confirm
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" class="d-flex justify-center py-0">
        <v-card elevation="0" class="mt-0">
          <v-btn
            text
            @click="printData"
            class="pa-0"
            style="padding-left: 5px !important"
          >
            <v-icon class="pr-1" large color="black">mdi-printer </v-icon>
            Print
          </v-btn>
          <v-btn
            text
            @click="printData"
            style="padding-left: 0px !important; padding-right: 10px !important"
          >
            <v-icon class="pr-0" large color="black">mdi-download </v-icon>
            download
          </v-btn>
        </v-card>
      </v-col>
    </v-row>
    <v-snackbar v-model="snackbar" centered :color="color">
      {{ text }}
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    expanded: [],
    singleExpand: false,
    orderAllArray: [],
    companyinfo: {},
    snackbar: false,
    text: "",
    color: "",
    billFromInfo: {},
    billTo: {},
    quantity: "",
    approve_quantity: 0,
    damage_quantity: 0,
    not_found_quantity: 0,
    value_store: [],
    confirmbtn: false,
    quantityform: false,
    contact: {},
    user: {},
    headers: [
      {
        text: "Name",
        sortable: false,
        value: "product_info.product_name",
        class: "blue-grey lighten-4 text-body-2 tableheader",
      },
      {
        text: "Barcode",
        sortable: false,
        value: "barcode",
        class: "blue-grey lighten-4 text-body-2 tableheader",
      },
      {
        text: "Color",
        value: "specification_info.color",
        sortable: false,
        class: "blue-grey lighten-4 text-body-2 tableheader",
      },
      {
        text: "Size",
        value: "specification_info.size",
        sortable: false,
        class: "blue-grey lighten-4 text-body-2 tableheader",
      },
      {
        text: " Variant",
        value: "product_variant",
        sortable: false,
        class: "blue-grey lighten-4 text-body-2 tableheader ",
      },
      {
        text: "Status",
        value: "status",
        sortable: false,
        class: "blue-grey lighten-4 text-body-2 tableheader ",
      },
      {
        text: "Quantity ",
        value: "requested_qty",
        sortable: false,
        class: "blue-grey lighten-4 text-body-2 tableheader",
      },

      {
        text: " ",
        value: "data-table-expand",
        sortable: false,
        class: "blue-grey lighten-4 text-body-2 tableheader",
      },
    ],
    request: {},
  }),

  methods: {
    initialize(item) {
      axios
        .get(`hub/get_hub_info/${this.$route.query.id}/`)
        .then((response) => {
          this.orderAllArray = response.data.data.transfer_info;
          this.billTo = response.data.data.request_getter_info;
          this.companyinfo = response.data.data.request_setter_info;
          this.request = response.data.data;
        });
    },
    printData() {
      this.$htmlToPaper("dataapp");
    },
    Approveqty(item) {
      let datas = {
        id: item.id,
        approved_qty: parseInt(item.approved_qty),
        damaged_qty: parseInt(item.damaged_qty),
        not_found_qty: parseInt(item.not_found_qty),
        request_getter: item.request_getter,
        getter_flag: item.getter_flag,
      };

      this.value_store.push(datas);
      this.confirmbtn = true;

      this.orderAllArray = this.orderAllArray.filter(
        (contact) => contact.id != item.id
      );
    },

    AllConfirm() {
      let payload = {
        id: this.request.id,
        request_setter: this.request.request_setter,
        setter_flag: this.request.setter_flag,
        transfer_info: this.value_store,
      };

      axios
        .post(`hub/approve_transfer_request/`, payload)
        .then((response) => {
          if (response.data.success) {
            this.text = response.data.message;
            this.color = "success";
            this.snackbar = true;
            setTimeout(() => {
              window.location.reload();
            }, 1000);
          } else {
            this.text = response.data.message;
            this.color = "error";
            this.snackbar = true;
          }
        })
        .catch((err) => {
          this.text = "Something want wrong!";
          this.color = "error";
          this.snackbar = true;
        });
    },
  },
  created() {
    this.user = JSON.parse(localStorage.getItem("user"));
  },
  mounted() {
    this.initialize();
  },
};
</script>

<style scoped>
.tableheader {
  max-height: 32px !important;
}
</style>